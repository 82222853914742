<template>
	<div>

		<div id="selecat">

			<div
				id="normalDiv"
				class="d-flex flex-column pa-8">
				<v-card class="px-7 py-4 rounded-xl mx-auto"
						style="max-width: 1000px !important"
				>
					<div class="text-center">
						<h1 style="font-size: 18px !important"
							class="mb-3"
						>
							Prepara't per a la Selectivitat: Exàmens Resolts i Recursos d'Estudi</h1>
<!--						<h2 style="color: #2E90F9; font-size: 20px !important;" class="mt-1">Fet per l'equip d'examenselectivitat.cat</h2>-->
					</div>
					<p
						class="mb-0"
						style="font-size: 14px !important;"
					>
						Benvingut a la nostra pàgina web dedicada als exàmens de selectivitat! Som una plataforma en
						línia que ofereix una àmplia col·lecció d'exàmens de selectivitat resolts i materials
						d'estudi per als estudiants que es preparen per a aquest important examen acadèmic. Amb
						l'objectiu d'ajudar els estudiants a assolir els seus objectius educatius, proporcionem una
						gran varietat de recursos, incloent-hi exàmens de selectivitat anteriors, un buscador de les
						notes de tall i una calculadora per saber la nota final de les PAU.
						<br><br>
						Els nostres exàmens de selectivitat estan classificats per anys i temes, perquè puguis
						centrar-te en àrees específiques en les quals necessitis millorar. A més, oferim respostes
						detallades i explicacions per ajudar-te a comprendre els conceptes i millorar les teves
						habilitats de resolució de problemes.
						<br><br>
						No esperis més per començar la teva preparació per als exàmens de selectivitat. Amb els
						nostres recursos educatius de qualitat, estaràs ben equipat per superar els desafiaments de
						l'examen i aconseguir els resultats que desitges. Comença avui mateix i fes un pas valuós
						cap al teu futur acadèmic!
					</p>

<!--					<div class="d-flex">-->
<!--						<v-spacer></v-spacer>-->
<!--						<v-btn-->
<!--							v-if="!showDesc"-->
<!--							@click="showDesc=true"-->
<!--							text-->
<!--							color="primary"-->
<!--							class="text-none"-->
<!--						>-->
<!--							Veure text complet-->
<!--						</v-btn>-->
<!--					</div>-->

<!--					<div class="d-flex pt-4">-->
<!--						<a href="/selecat#table" class="mx-auto">-->
<!--							<v-btn color="primary" class="rounded-lg font-rubik text-none" depressed-->
<!--								   style="font-size: 16px"-->
<!--							>-->
<!--								Anar a la taula d'exàmens-->
<!--							</v-btn>-->
<!--						</a>-->
<!--					</div>-->
				</v-card>
			</div>


			<div
				:style="$vuetify.breakpoint.smAndDown ? 'top: 0px; height: 100px;' : 'top: 73px; height: 108px;'"
				style="width: 100vw; position: sticky; left: 0; background: #F4F4F4; z-index: 2"></div>


			<v-card
				v-if="branches && branches['m_subjectsBranches']"
				class="mt-4 mx-auto rounded-xl"
				outlined
				tile
				flat
				style="width: fit-content"
				id="table"
			>
				<table
					style="border-collapse:separate;border-radius:6px; border-spacing: 0;position: relative;">

					<tbody style="position: sticky; z-index: 5; background: white"
						   :style="$vuetify.breakpoint.smAndDown ? 'top: 10px' : 'top: 90px'"
					>
					<tr>
						<th class="rounded-tl-xl"
							style="position:relative; z-index: 5; background: #3B49E6"
						>
							<img src="../../assets/logo.png"
								 style="object-fit: contain; height: 60px; position: absolute; top: 10px; left: 0; z-index: 5"
							>
						</th>
						<th
							v-for="(any, idx_year) in years"
							class="year"
							:class="(idx_year === years.length-1 ? 'rounded-tr-xl' : '')"
							colspan="2"
							style="border-left: solid 1px #a2c8ff; background: #71b7ff"
							:style="idx_year < years.length-1 ? 'border-right: solid 4px #a2c8ff;' : ''"
						>
							<div class="py-3">
							  <span class="font-rubik"
									style="font-size: 20px">
								{{ any }}
							  </span>
							</div>
						</th>
					</tr>
					</tbody>
					<tbody
					>
					<tr style="line-height: 30px; z-index: 2; position: sticky;"
						:style="$vuetify.breakpoint.smAndDown ? 'top: 64px' : 'top: 144px'"
						class="shadow-md"
					>
						<th style="background: #3B49E6"></th>
						<template v-for="(mes, year_idx) in years">
							<th
								class="mes text-center px-2 py-1" colspan="1"
								style="border-left: solid 1px #eaf3ff; min-width: 53px; border-right: 1px solid rgb(244 244 244);"
							>
							  <span class="font-rubik font-weight-medium"
									:style="year_idx < realYears ? '' : 'display: none'"
									style="font-size: 15px">
								Juny
							  </span>
							</th>
							<td class="mes text-center px-2 py-1"
								style="min-width: 53px"
								:style="mes < years.length-1 ? 'border-right: solid 4px #eaf3ff;' : ''"
								colspan="1">
							   <span class="font-rubik font-weight-medium"
									 :style="year_idx < realYears ? '' : 'display: none'"
									 style="font-size: 15px">
								Set.
							  </span>
							</td>
						</template>

						<td class="mes" colspan="2">
							<!--          Materies-->
						</td>

					</tr>

					<template v-for="(branch, idx_branch) in branches['m_subjectsBranches']">
						<tr>
							<td
								:colspan="3"
								style="background: #dbdefd !important; border-right: 4px solid white"
								class="px-3 pt-2"
							>
							  <span class="font-rubik font-weight-bold"
									style="font-size: 17px; color: #333333"
							  >
								{{ branch['branch_name'] }}
							  </span>
							</td>
							<template
								v-for="y in years.length-1"
							>
								<td
									style="background: #dbdefd; border-right: 1px solid #e9eaff; border-left: 1px solid #e9eaff"
								></td>
								<td
									style="background: #dbdefd;"
									:style="y < years.length-1 ? 'border-right: solid 4px #e9eaff;' : ''"
								></td>
							</template>
						</tr>

						<tr v-for="(assignatura, idx_assig) in branch['subjects']"
						>
							<th class="assignatura text-start px-6"
								scope="row"
								style="line-height: normal !important;"
								:class="(idx_branch === branches['m_subjectsBranches'].length-1 && idx_assig === branch['subjects'].length-1 ? 'rounded-bl-xl' : '')"
							>
								  <span class="font-rubik font-weight-medium">
								{{ assignatura["subject_name"] }}
								</span>
							</th>
							<template
								v-for="(year, year_idx) in years"
							>
								<td v-for="month in ['Juny', 'Setembre']"
									class="rounded-0 py-2 justify-center"
									:style="(month === 'Setembre' ? (year_idx < years.length -1 ? 'border-right: solid 4px white !important; ' : '') :
                   'border-left: solid 1px white !important; border-right: solid 1px rgb(244 244 244) !important; ')
                   + (idx_assig % 2 === 0 ? 'background: #f7f7f7;' : 'background: #e9e9e9;')"
									style="position: relative;"
									:key="`td_${idx_branch}_${idx_assig}_${year_idx}_${month}`"
									:class="idx_branch === branches['m_subjectsBranches'].length-1 && year_idx === years.length-1 && idx_assig === branch['subjects'].length-1? 'rounded-br-xl' : ''"
								>

									<div
										v-if="year_idx < realYears"
										@click="goToExam(assignatura, year, month)"
										class="d-flex align-center justify-center"
										style="min-height: 38px"
									>
										<v-tooltip top
												   open-delay="200"
												   color="transparent"
												   class="pa-0"
												   transition="slide-y-reverse-transition"
										>
											<template v-slot:activator="{ on, attrs }">
												<img
													src="../../assets/pdf-icon-1.gif"
													alt="Examen"
													class="rounded"
													style="width: 22px !important; object-fit: contain; min-width: 22px; cursor: pointer"
													v-bind="attrs"
													v-on="on"
												>
											</template>

											<div
												style="background: white"
												class="rounded-lg shadow-sm"
											>
												<div style="background: #3B49E6"
													 class="py-2 px-3 rounded-lg rounded-b-0 white--text d-flex flex-column"
												>
                        <span class="font-weight-medium white--text font-rubik"
							  style="font-size: 16px"
						>
                          {{ assignatura['subject_name'] }}
                        </span>
												</div>
												<div class="py-2 px-3 font-rubik black--text">
													Examen {{ year }} {{ month }}
												</div>
											</div>
										</v-tooltip>
									</div>
								</td>

							</template>
						</tr>

					</template>

					</tbody>
				</table>
			</v-card>
		</div>

		<v-card
			v-if="totalPages > 1 && showPagesMenu"
			style="position: fixed; bottom: 30px;"
			class="pa-2 rounded-lg d-flex align-center"
			:class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
			:style="$vuetify.breakpoint.smAndDown ? 'left: calc(50vw - 80px)' : 'left: calc(50vw - 212px)'"
		>
			<v-btn
				@click="previousPage()"
				class="rounded-lg text-none py-2 mr-1"
				depressed
				:disabled="selectedPage <= 0"
				color="primary"
				style="letter-spacing: initial; font-size: 17px"
			>
				<v-icon left>
					{{ mdiChevronLeft }}
				</v-icon>
				Més recents
			</v-btn>
			<span class="font-rubik px-10"
				  :class="$vuetify.breakpoint.smAndDown ? 'my-3' : ''"
			>{{ selectedPage + 1 }} / {{ totalPages }}</span>
			<v-btn
				@click="nextPage()"
				:disabled="selectedPage >= totalPages - 1"
				class="rounded-lg text-none py-2 ml-1"
				depressed
				color="primary"
				style="letter-spacing: initial; font-size: 17px"
			>
				Més antics
				<v-icon right>
					{{ mdiChevronRight }}
				</v-icon>
			</v-btn>
		</v-card>


		<v-card class="mx-auto pa-10 text-center rounded-xl"
				max-width="1000px"
				flat
		>
			<h2 style="font-size: 24px !important;" class="mt-1">Comparativa: Selecat vs Examenselectivitat.cat</h2>

			<div class="d-flex flex-column mt-4">
				<v-card class="mb-4 rounded-lg py-2 d-flex font-rubik"
						style="font-size: 18px"
						flat
				>
					<div style="flex: 2"></div>
					<div style="flex: 1" class="text-center">
						<span class="font-rubik font-weight-medium">
							Selecat
						</span>
					</div>
					<div style="flex: 1" class="text-center">
						<span class="font-rubik font-weight-medium">
							Examenselectivitat
						</span>
					</div>
				</v-card>
				<v-card v-for="row of comparacio"
						class="mb-4 rounded-lg py-2 d-flex px-4"
						flat
						style="border: solid 2px lightgrey"
				>
					<div style="flex: 2">
						<h3 class="font-rubik font-weight-regular text-start"
							style="font-size: 16px"
						>
							{{row['title']}}
						</h3>
					</div>
					<div
						v-for="emoji in row['emojis']"
						style="flex: 1">
						<span class="font-rubik font-weight-medium"
							  style="font-size: 18px !important;"
						>
							{{emoji}}
						</span>
					</div>
				</v-card>
			</div>

			<div class="d-flex pt-4">
				<a href="/selecat#table" class="mx-auto">
					<v-btn color="primary" class="rounded-lg font-rubik text-none" depressed
						   style="font-size: 16px"
					>
						Anar a la taula d'exàmens
					</v-btn>
				</a>
			</div>
		</v-card>

		<v-card color="rgb(244 244 244)"
				class="pb-12"
		></v-card>

	</div>
</template>

<script>
import Branches from "./Branches";
import {mdiChevronRight, mdiChevronLeft} from '/src/assets/mdi.json'

export default {
	name: "Selecat",
	metaInfo() {
		return {
			title: `✅ Exàmens de les PAU (nova versió de Selecat)`,
			titleTemplate: '%s - examenselectivitat',
			meta: [
				{charset: 'utf-8'},
				{
					name: 'description',
					content: `Benvingut a la nostra pàgina web dedicada als exàmens de selectivitat! Som una plataforma en línia que ofereix una àmplia col·lecció d'exàmens de selectivitat resolts i materials d'estudi per als estudiants que es preparen per a aquest important examen acadèmic.`
				},
				{name: 'viewport', content: 'width=device-width, initial-scale=1'}
			]
		}
	},
	async mounted() {
		this.branches = await Branches.build();

		// Set years columns
		const normalDiv = document.getElementById("normalDiv");
		let bounds = normalDiv.getBoundingClientRect();

		const tableW = bounds.width - 16;
		const columnW = 107;
		const numberOfColumns = Math.floor((tableW - 160) / columnW)
		this.numberOfColumns = numberOfColumns

		this.totalPages = Math.ceil(this.allYears.length / numberOfColumns)

		this.setPage()

		// Set padding bottom
		document.getElementById("selecat").style.paddingBottom = ((window.innerHeight) / 3) + "px"


		// Check scroll to show pages selector
		window.addEventListener("scroll", (event) => {
			const scroll = window.pageYOffset || document.documentElement.scrollTop;
			if (scroll) {
				console.log(scroll)
				if (scroll >= bounds.height) {
					this.showPagesMenu = true;
				} else {
					this.showPagesMenu = false;
				}
			}
		});
	},
	data() {
		return {
			showDesc: false,
			allYears: [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2018, 2017, 2016,
				2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000
			],
			years: [],
			branches: [],
			leftPos: null,
			topPos: null,
			selectedExam: null,
			selectedAssignatura: null,
			selectedPage: 0,
			numberOfColumns: null,
			totalPages: null,
			showPagesMenu: false,
			mdiChevronRight: mdiChevronRight,
			mdiChevronLeft: mdiChevronLeft,
			realYears: 0,
			classifiedExams: {
				"1": [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"2": [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"3": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"4": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"5": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"6": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"7": [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"8": [2021, 2022, 2023],
				"9": [2010, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"10": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"11": [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"12": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"13": [2018, 2019, 2020, 2021, 2022, 2023],
				"14": [2018, 2019, 2020, 2021, 2022, 2023],
				"15": [2018, 2019, 2020, 2021, 2022, 2023],
				"16": [2018, 2019, 2020, 2021, 2022, 2023],
				"17": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"18": [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"19": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"20": [2018, 2019, 2020, 2021, 2022, 2023],
				"21": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"22": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"23": [2018, 2019, 2020, 2021, 2022, 2023],
				"24": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"25": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"26": [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"27": [2018, 2019, 2020, 2021, 2022, 2023],
				"28": [2017, 2018, 2019, 2020, 2021, 2022, 2023],
				"29": [2018, 2019, 2020, 2021, 2022, 2023]
			},
			comparacio: [
				{
					title: "Tots els exàmens de les PAU (Inclòs 2024)",
					emojis: ["✅", "✅"]
				},
				{
					title: "Escoltar els audios i listenings",
					emojis: ["❌", "✅"]
				},
				{
					title: "Autocorreció a l'instant",
					emojis: ["❌", "✅"]
				},
				{
					title: "Problemes classificats per temes",
					emojis: ["❌", "✅"]
				},
				{
					title: "Buscador de les notes de tall",
					emojis: ["❌", "✅"]
				},
				{
					title: "Calculadora per la Selectivitat",
					emojis: ["❌", "✅"]
				},
			]
		}
	},
	methods: {
		isClassified(assignatura, year) {
			return this.classifiedExams[assignatura['subject_id']] && this.classifiedExams[assignatura['subject_id']].includes(year)
		},
		goToExam(assignatura, year, month) {

			if (this.isClassified(assignatura, year))
				this.$router.push({path: '/selectivitat/' + assignatura['subject_name'] + '/examens/' + year + '/' + month})
			// Go to pdf
			else {
				if (this.$vuetify.breakpoint.smAndDown) {
					const pdf = `https://examenselectivitat.cat:3000/api/pdfs/${assignatura["subject_path"]}/${year}/${month}/Enunciat`;
					window.open(pdf);
				} else {
					this.$router.push({path: '/selectivitat/' + assignatura['subject_name'] + '/pdf/' + year + `/${month}/Enunciat`})
				}
			}
		},
		setPage() {
			this.realYears = 0
			this.years = []
			for (let i = this.selectedPage * this.numberOfColumns; i < (this.selectedPage + 1) * this.numberOfColumns; i++) {
				if (i < this.allYears.length)
					this.realYears++;
				this.years.push(this.allYears[i])
			}
		},
		nextPage() {
			this.selectedPage++;
			this.setPage()
			// window.scrollTo(0, 0)
		},
		previousPage() {
			this.selectedPage--;
			this.setPage()
			// window.scrollTo(0, 0)
		}
	}

}

</script>

<style scoped>
.year {
//background: linear-gradient(-90deg, #015ABB 4%, #2E90F9 96%); background: #DAA520; color: white; font-weight: bold;
}

.assignatura {
	background: #3B49E6;
	color: white;
	font-weight: bold;
	border-style: hidden;
	min-width: 150px;
}

.punteado {
	border-style: inset;
	border-width: 1px;
	border-color: #778899;
	font-size: 10pt;
}

.mes {
	background: #e3ecf5;
}

.pdf-icon {
	object-fit: contain;
	align-content: center;
	align-self: center;
}

#normalDiv {
	background-color: #e5e5f7;
	opacity: 0.8;
	background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #e5e5f7 10px), repeating-linear-gradient(#444cf755, #444cf7);
}


</style>
